//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  methods: {
    sendEventRedirect($event, eventName) {
      window.sendEventRedirect($event, eventName)
    },
    checkIfScrolled() {
      const scrolledClass = 'is-scrolled'
      const navEl = document.getElementById('nav')
      const hasClass = navEl?.classList.contains(scrolledClass)
      const isTop = window.scrollY <= 40
      if (!isTop && !hasClass) {
        navEl?.classList.add(scrolledClass)
      } else if (isTop && hasClass) {
        navEl?.classList.remove(scrolledClass)
      }
    },
  },
  beforeMount() {
    window.addEventListener('scroll', this.checkIfScrolled)
  },
  beforeDestroy() {
    window.removeEventListener('scroll', this.checkIfScrolled)
  },
}
