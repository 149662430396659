




















import mixpanel from 'mixpanel-browser'

export default {
  beforeMount() {
    mixpanel.init('dbb30c5b5f213df569bdf762396a1a28')
  },
}
