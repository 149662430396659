import { render, staticRenderFns } from "./BaseFooter.vue?vue&type=template&id=c6bfbe9a&scoped=true&"
var script = {}
import style0 from "./BaseFooter.vue?vue&type=style&index=0&id=c6bfbe9a&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "c6bfbe9a",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {Logo: require('/opt/build/repo/components/Logo.vue').default})
